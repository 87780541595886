import type { TicketCallTranscriptType, TicketPreviewType, TicketMessageType, TableMessageType } from '@/definitions/tickets/types'
import { ticketsStatusOptions } from '@/definitions/_general/_data/optionsList'

export const firstTicket: TicketPreviewType = {
  title: 'Trouble with patient login - urgently need help',
  date: '1 Apr, 2:24 pm',
  id: '87687',
  status: {
    color: 'red',
    outline: false,
    name: 'new',
  },
  priority: '100',
  person: {
    uid: '1',
    fullName: 'J',
    firstName: 'J',
    lastName: 'J',
    avatar: '',
    avatarColor: 'orange',
  },
}

export const secondTicket: TicketPreviewType = {
  title: 'Login issue: unable to access account',
  date: '4 Apr, 1:12 pm',
  id: '45456',
  status: {
    color: 'blue',
    outline: true,
    name: 'pending',
  },
  priority: '300',
  person: {
    uid: '1',
    fullName: 'E',
    firstName: 'E',
    lastName: 'E',
    avatar: '',
    avatarColor: 'green',
  },
}

export const newMessage: TicketMessageType = {
  person: {
    uid: '',
    fullName: 'John Doe',
    firstName: '',
    lastName: '',
    avatarColor: 'orange',
    avatar: '',
  },
  text: 'Hello, so I have update about your case, we will contact with you asap.',
  date: 'Just now',
  to: 'ralphedwards@mail.com',
  isNote: false,
  isAgent: true,
  collapsed: false,
  unsend: true,
  events: [],
}

export const ticketsMergeOptions = [
  {
    id: '322534',
    priority: '200',
    borderTop: true,
    title: 'Login issue: unable to access account',
    status: {
      name: 'pending',
      color: 'blue',
      outline: true,
    },
  },
  {
    id: '534322',
    priority: '300',
    borderTop: true,
    title: 'New feature request: multi-factor login a...',
    status: {
      name: 'open',
      color: 'orange',
      outline: true,
    },
  },
  {
    id: '334225',
    priority: '200',
    borderTop: true,
    title: 'Bug report: login page loading error',
    status: {
      name: 'open',
      color: 'orange',
      outline: true,
    },
  },
  {
    id: '332254',
    priority: '400',
    borderTop: true,
    title: 'Feedback: user experience improvements...',
    status: {
      name: 'pending',
      color: 'blue',
      outline: true,
    },
  },
]

export const simulateTicketMessage: TableMessageType = {
  id: '1',
  type: 'message',
  messageData: {
    person: {
      uid: '',
      fullName: 'Textmagic bot',
      firstName: '',
      lastName: '',
      avatarColor: 'orange',
      avatar: require('@/assets/images/textmagic-circle.svg'),
    },
    text: 'Hello John 👋\n\nWe are thrilled to have you onboard! In this demo ticket, you will have a chance to see how simple and effective our system is at managing tickets.\n\nDo not hesitate to test out how tickets work.\nJust reply to Textmagic bot, and you will even get replies back from our bot, like you would be communicating with a real person.\n\nEnjoy your exploration!\n\nWarm regards,\nTextmagic bot',
    date: '1 Apr, 2:24 pm (Just now)',
    to: 'support@company.com',
    isNote: false,
    isAgent: false,
    events: [],
    collapsed: false,
  },
}

export const newTicketItem = {
  id: '1',
  priority: '100',
  subject: 'This is your demo ticket',
  requester: {
    avatar: require('@/assets/images/textmagic-circle.svg'),
    fullName: 'Textmagic bot',
  },
  assignee: {
    fullName: 'John Doe',
    avatarColor: 'orange',
  },
  statusTicket: ticketsStatusOptions.find((e: any) => e.name === 'new'),
}

export const ticketMessageCallItem: TicketMessageType = {
  person: {
    uid: '',
    fullName: 'John Doe',
    firstName: '',
    lastName: '',
    avatarColor: 'orange',
    avatar: '',
  },
  date: 'Just now',
  to: '(855) 543-5326',
  isAgent: true,
  collapsed: false,
}

export const ticketCallTranscript: TicketCallTranscriptType[] = [
  {
    time: '0:02',
    person: 'John Doe',
    isAgent: true,
    text: 'Good morning, this is John Doe at [Healthcare Provider] Support. How can I assist you today?',
  },
  {
    time: '0:32',
    person: 'Ralph Edwards',
    text: 'Hi John, I’m Ralph Edwards. I can’t log into the patient portal and I need to check my appointment details urgently. Hi John, I’m Ralph Edwards. I can’t log into the patient portal and I need to check my appointment details urgently',
  },
  {
    time: '0:55',
    isAgent: true,
    person: 'John Doe',
    text: 'I\'m sorry to hear that, Ralph. Could you please confirm your email or patient ID for me?',
  },
  {
    time: '1:15',
    person: 'Ralph Edwards',
    text: 'It\'s ralph.edwards@example.com.',
  },
  {
    time: '1:20',
    person: 'John Doe',
    isAgent: true,
    text: 'Thank you, Ralph. Now, what error message are you seeing when you try to log in?',
  },
  {
    time: '1:25',
    person: 'Ralph Edwards',
    text: 'It keeps saying my password is incorrect, and I haven’t received the reset email.',
  },
  {
    time: '1:30',
    person: 'John Doe',
    isAgent: true,
    text: 'Let’s try sending another reset email. I’ve just sent it; please check your inbox and spam folder.',
  },
  {
    time: '1:36',
    person: 'Ralph Edwards',
    text: 'Got it this time, it was in spam. Just a moment... Okay, I’ve reset my password and I\'m logged in now.',
  },
  {
    time: '1:42',
    person: 'John Doe',
    isAgent: true,
    text: 'Fantastic! Is there anything else I can help you with?',
  },
  {
    time: '1:56',
    person: 'Ralph Edwards',
    text: 'That\'s all, thanks for your help!',
  },
  {
    time: '2:04',
    person: 'John Doe',
    isAgent: true,
    text: 'You\'re welcome, Ralph. Have a great day and feel free to reach out if you need further assistance. Goodbye!',
  },
  {
    time: '2:16',
    person: 'Ralph Edwards',
    text: 'Goodbye!',
  },
]

export const detailsSidebarActivities = [
  {
    title: 'Inbound call',
    type: 'chat',
    date: '18 Sep, 1:45 pm',
    status: 'open',
  },
  {
    title: 'Outbound call',
    type: 'chat',
    date: '19 Oct, 1:45 pm',
    status: 'past',
  },
  {
    title: 'My account is not working, need some long text',
    type: 'ticket',
    date: '19 Oct, 1:45 pm',
    status: 'past',
  },
  {
    title: 'Issue with API and documentation',
    type: 'ticket',
    date: '19 Oct, 1:45 pm',
    status: 'open',
  },
  {
    title: 'Issue with API and documentation',
    type: 'ticket',
    date: '18 Sep, 1:45 pm',
    status: 'past',
  },
  {
    title: 'Issue with API and documentation',
    type: 'ticket',
    date: '18 Sep, 1:45 pm',
    status: 'past',
  },
  {
    title: 'Chat with (754) 874-4758',
    type: 'mobileChat',
    date: '18 Sep, 1:45 pm',
    status: 'open',
  },
  {
    title: 'Live chat request',
    type: 'chat',
    date: 'Today, 10:24 am',
    status: 'open',
  },
]

export const ticketMessageInfo = [
  {
    label: 'From',
    value: 'Ralph Edwards via Textmagic',
    sender: 'ticket-58UQOacFx ACg19eK3bWFC3@email.textmagic.com',
  },
  {
    label: 'Reply-to',
    value: 'Textmagic',
    sender: 'support@company.com',
  },
  {
    label: 'To',
    value: '"Main support"',
    sender: 'support@company.com',
  },
  {
    label: 'Date',
    value: '3 Apr 2024, 14:10',
  },
]
