import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4c755e86"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "tickets-merge-preview" }
const _hoisted_2 = { class: "tickets-merge-preview__top" }
const _hoisted_3 = { class: "subhead-semi-bold-16 lh-24" }
const _hoisted_4 = { class: "tickets-merge-preview__date" }
const _hoisted_5 = { class: "d-flex align-center justify-between" }
const _hoisted_6 = { class: "tickets-merge-preview__infos" }
const _hoisted_7 = { class: "lh-20 font-weight-medium" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_tm_status = _resolveComponent("tm-status")!
  const _component_tm_priority = _resolveComponent("tm-priority")!
  const _component_tm_avatar = _resolveComponent("tm-avatar")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.title), 1),
      _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.date), 1)
    ]),
    _createElementVNode("div", _hoisted_5, [
      _createElementVNode("div", _hoisted_6, [
        _createElementVNode("div", _hoisted_7, _toDisplayString(_ctx.id), 1),
        _createVNode(_component_tm_status, _normalizeProps(_guardReactiveProps(_ctx.status)), null, 16),
        _createVNode(_component_tm_priority, { type: _ctx.priority }, null, 8, ["type"])
      ]),
      _createVNode(_component_tm_avatar, {
        color: _ctx.person.avatarColor,
        url: _ctx.person.avatar,
        name: _ctx.person.fullName,
        size: "medium"
      }, null, 8, ["color", "url", "name"])
    ])
  ]))
}