
import { defineComponent } from 'vue'
import type { PersonType, PriorityType, StatusType } from '@/definitions/shared/types'
import type { PropType } from 'vue'
import TmStatus from '@/components/shared/TmStatus.vue'
import TmPriority from '@/components/shared/TmPriority.vue'
import TmAvatar from '@/components/shared/TmAvatar.vue'

export default defineComponent({
  components: {
    TmStatus,
    TmPriority,
    TmAvatar,
  },
  props: {
    title: {
      type: String,
    },
    date: {
      type: String,
    },
    id: {
      type: String,
    },
    status: {
      type: Object as PropType<StatusType>,
    },
    priority: {
      type: String as PropType<PriorityType>,
    },
    person: {
      type: Object as PropType<PersonType>,
    },
  },
})
