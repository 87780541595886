
import { defineComponent, ref } from 'vue'
import TmFormLine from '@/components/shared/TmFormLine.vue'
import TmButton from '@/components/shared/TmButton.vue'
import TmModal from '@/components/shared/modal/TmModal.vue'
import TmStatus from '@/components/shared/TmStatus.vue'
import TmPriority from '@/components/shared/TmPriority.vue'
import TmAlert from '@/components/shared/TmAlert.vue'
import TicketsMergePreview from '@/components/modals/tickets/ticketsMerge/TicketsMergePreview.vue'
import { firstTicket, secondTicket, ticketsMergeOptions } from '@/definitions/tickets/data'

export default defineComponent({
  components: {
    TmFormLine,
    TmPriority,
    TmStatus,
    TicketsMergePreview,
    TmAlert,
    TmButton,
    TmModal,
  },
  props: {
    multipleMerge: {
      type: Boolean,
    },
    btnText: {
      type: String,
      default: 'Merge tickets',
    },
    label: {
      type: String,
      default: 'Merge into',
    },
  },
  setup() {
    const ticketSelected = ref(false)
    const options = ticketsMergeOptions

    return {
      ticketSelected,
      options,
      firstTicket,
      secondTicket,
    }
  },
})
